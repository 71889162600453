import React from "react"
import Container from "react-bootstrap/Container"
import ThreeCardsShadow from "../threeCardsShadow"
import { GiFire, GiHandheldFan } from "react-icons/gi"
import { SiHandshake } from "react-icons/si"

export default function ActivitiesDance() {
  return (
    <Container>
      <h2 className="lstitle text-uppercase">Dance</h2>
      <ThreeCardsShadow
        title="Salsa and Bachata"
        icon={<GiHandheldFan />}
        text="Livebands often entertain the dancefloors in Costa Rica. Dance Salsa and Bachata to live music!"
        title2="Merengue and Reggaeton"
        icon2={<GiFire />}
        text2="The same is true for Merengue and Reggaeton. It's a latino country, after all!"
        title3="At Workshops and Parties"
        icon3={<SiHandshake />}
        text3="Learn to dance or learn new dance figures: with the workshops during the voyage."
      />
    </Container>
  )
}
