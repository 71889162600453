import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"

import Cta from "../components/cta"
import TextImg from "../components/textImg"
import TextImgSmall from "../components/textImgSmall"

import ImgTextSmall from "../components/imgTextSmall"
import ImgText from "../components/imgText"
import Newsletter from "../components/newsletter"
import ThreeCardsIcons from "../components/threeCardsIcons"
import { graphql } from "gatsby"
import { GiHeartInside, GiAngelWings, GiHiking } from "react-icons/gi"

import ActivitiesCostaRica from "../components/activities/costarica"
import ActivitiesDance from "../components/activities/dance"

import TestimonialCostaRica from "../components/testimonials/costaRica"
import TestimonialCocoa from "../components/testimonials/cocoaTrail"

import Seo from "../components/Seo/seo"
import WebsiteJson from "../components/Seo/websiteJson"

export default function Home({ data }) {
  return (
    <Layout>
      <Seo
        title="Tour Details"
        description="Make a journey of a lifetime in Costa Rica. Dance Salsa, Bachata and Reggaeton. Discover dancefloors, volcanoes, beaches and learn new skills."
      />
      <WebsiteJson />
      <Hero
        title="Dance and Travel in Costa Rica"
        subtitle="Discover the most amazing places and dance Salsa, Bachata and Reggaeton"
        btn="MAKE YOUR RESERVATION HERE"
        img={data.bg}
      />

      <ThreeCardsIcons
        title="For Dancers"
        text="Dance Salsa, Bachata, Cumbia or Reggaeton with dancers from Latin America in Paradise. Learn new figures and new dances - with many hours of included dance classes!"
        icon={<GiHeartInside />}
        title2="For Adventurers"
        text2="Discover jungle trails, volcanoes, waterfalls and experience authentic and gentle Costa Rica! This journey takes you all over the country!"
        icon2={<GiAngelWings />}
        title3="For non-Dancers"
        text3="learn to dance in preparation workshops and during the trip - you have helpful companions and a dance teacher at your side. Or enjoy the trip without dancing!"
        icon3={<GiHiking />}
      />

      <ImgTextSmall
        title="RELAX at Pristine Beaches"
        text="Take a time-out on untouched beaches in Costa Rica."
        link="/booking"
        btn="Reserve Your Spot Now"
        img={data.beach}
        imgAlt="Discover Pristine Beaches in Costa Rica"
      />
      <ActivitiesDance />
      <TestimonialCostaRica />
      <div className="mt-5 mb-5"></div>
      <TextImgSmall
        title="DANCE in Costa Rica"
        text="Dance with the masters in Costa Rica, discover authentic parties and dance Salsa, Bachata, Cumbia and Reggaeton with the masters!"
        link="/booking"
        btn="Enjoy Dancing"
        img={data.dancing}
        imgAlt="Dancing in Costa Rica"
      />
      <div className="mt-5 mb-5"></div>
      <Cta
        title="Allow yourself to make journey of a lifetime!"
        subtitle="Make memories you will cherish and never want to miss."
        link="/booking"
        btn="Travel to Costa Rica"
        bgcolor="#ec6f91"
      />
      <div className="mt-5 mb-5"></div>
      <ImgText
        title="MEET your guide"
        text="Natalia, Costa Rican dancer living in Germany, will accompany you during the whole tour and provide you with useful tips and well kept secrets!"
        link="/booking"
        btn="Reserve Your Spot Now"
        img={data.natalia}
        imgAlt="Discover Pristine Beaches in Costa Rica"
      />
      <ActivitiesCostaRica />
      <div className="mt-5 mb-5"></div>
      <TextImg
        title="Adventure awaits YOU"
        text="Follow jungle trails, see exotic animals, ride to waterfalls and climb on top of volcanos!"
        link="/booking"
        btn="To Your Adventure"
        img={data.croco}
        imgAlt="Wild Crocodile in Costa Rica"
      />
      <div className="mt-5 mb-5"></div>
      <TestimonialCocoa />
      <div className="mt-5 mb-5"></div>
      <Newsletter />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    beach: file(name: { eq: "endless-beach-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    croco: file(name: { eq: "crocodile-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    dancing: file(name: { eq: "dancing-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    bg: file(name: { eq: "beach-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    natalia: file(name: { eq: "natalia-pacheco" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
