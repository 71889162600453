import React from "react"

import { Link } from "gatsby"
import { ImArrowRight } from "react-icons/im"

export default function Cta({ title, subtitle, link, btn, bgcolor, txtcolor }) {
  return (
    <div>
      <div
        className="d-flex text-center"
        lc-helper="background"
        style={{
          minHeight: "20vh",
          //backgroundAttachment: "fixed",
          //backgroundSize: "cover",
          //backgroundPosition: "center center",
          backgroundColor: `${bgcolor}`,
          //backgroundImage: "url('')",
        }}
      >
        <div
          className={`lc-block my-3 align-self-center w-100 py-5 ${txtcolor}`}
        >
          <div>
            <h2 className="display-4 text-uppercase">{title}</h2>
            <p className="fs-5">{subtitle}</p>
          </div>
          <Link className="btn fs-4 btn-primary text-uppercase" to={link}>
            {btn}{" "}
            <ImArrowRight style={{ marginTop: "-3px", marginLeft: "3px" }} />
          </Link>
        </div>
      </div>
    </div>
  )
}
