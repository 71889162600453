import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export default function imgTextSmall({ title, text, link, btn, img, imgAlt }) {
  const image = getImage(img)
  return (
    <Container className="overflow-hidden p-0 mt-0">
      <Row>
        <div lc-helper="background" className="col-md-6  pt-5">
          <GatsbyImage
            image={image}
            alt={imgAlt}
            style={{
              minHeight: "50vh",
            }}
          />
        </div>

        <div
          className="col-md-6 my-auto text-center effect-4"
          style={{ backgroundColor: "#fff", padding: "5vw" }}
        >
          <div className="lc-block mb-3 ">
            <div>
              <h2 className="text-black-50">{title}</h2>
              <p className="lead">{text}</p>
            </div>
          </div>

          <div className="lc-block">
            <Link to={link} className="btn btn-primary text-uppercase">
              {btn}
            </Link>
          </div>
        </div>
      </Row>
    </Container>
  )
}
