import React from "react"
import Testimonial from "./testimonials"

export default function TestimonialCostaRica() {
  return (
    <Testimonial
      quote="UNFORGETTABLE"
      name="Nadine"
      topic="Revelling about her time in Costa Rica"
    />
  )
}
