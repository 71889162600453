import React from "react"
import Container from "react-bootstrap/Container"
import ThreeCardsShadow from "../threeCardsShadow"
import { GiJungle, GiCoffeeBeans, GiHotMeal } from "react-icons/gi"

export default function ActivitiesCostaRica() {
  return (
    <Container>
      <h2 className="lstitle text-uppercase">Experience Costa Rica</h2>
      <ThreeCardsShadow
        title="Hike in Rainforests and Jungles"
        text="Primary Rainforets, jungle-trails and more are at your fingertips in Costa Rica"
        icon={<GiJungle />}
        title2="Enjoy the Costa Rican Cuisine"
        text2="Gallo Pinto, Fresh Fish or Fruit, Empanada or Tortilla. Let your tongue be your guide."
        icon2={<GiHotMeal />}
        title3="Drink Volcano Cultivated Coffee"
        text3="Costa Rica has one of the highest rated coffees in the world - straight from their volcano fiels"
        icon3={<GiCoffeeBeans />}
      />
    </Container>
  )
}
