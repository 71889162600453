import React from "react"
import Testimonial from "./testimonials"

export default function TestimonialCocoa() {
  return (
    <Testimonial
      quote="An EXCELLENT tour you should not miss"
      name="Sacha"
      topic="Remembering the Cocoa Trails tour"
    />
  )
}
