import React from "react"
import JsonLd from "./jsonld"
import { useStaticQuery, graphql } from "gatsby"

export default function WebsiteJson() {
  const { site } = useStaticQuery(query)
  const {
    image,
    title,
    telephone,
    siteUrl,
    facebook,
    twitter,
    instagram,
    youtube,
    addressStreet,
    addressCity,
    addressPostal,
    lsDe,
    lsJga,
    lsReisen,
    price,
    priceMember,
  } = site.siteMetadata
  return (
    <JsonLd>
      {{
        "@context": "http://www.schema.org",
        "@type": "TravelAgency",
        name: `${title}`,
        url: `${siteUrl}`,
        sameAs: [
          `${lsDe}`,
          `${lsJga}`,
          `${lsReisen}`,
          `${twitter}`,
          `${instagram}`,
          `${youtube}`,
          `${facebook}`,
        ],
        logo: "",
        image: `${image}`,
        availableLanguage: ["German", "Spanish", "English"],
        description:
          "Organizing tours in Costa Rica, dance in paradise, climb volcanoes and relax at pristine beaches.",
        address: {
          "@type": "PostalAddress",
          streetAddress: `${addressStreet}`,
          addressLocality: `${addressCity}`,
          postalCode: `${addressPostal}`,
          addressCountry: "Germany",
        },
        telephone: `${telephone}`,
        priceRange: `€${priceMember}-${price}`,
      }}
    </JsonLd>
  )
}

const query = graphql`
  query WebsiteJson {
    site {
      siteMetadata {
        image
        title
        siteUrl
        telephone
        lsDe
        lsJga
        lsReisen
        facebook
        twitter
        instagram
        youtube
        addressStreet
        addressCity
        addressPostal
        price
        priceMember
      }
    }
  }
`
