import React from "react"
import Container from "react-bootstrap/Container"

export default function ThreeCards2({
  title,
  text,
  icon,
  title2,
  text2,
  icon2,
  title3,
  text3,
  icon3,
}) {
  return (
    <Container className="mt-3">
      <div className="row pt-4 pb-4 g-0">
        <div className="col-md-4 text-center">
          <div className="lc-block p-4">
            <div>
              <div className="display-6 text-primary">{icon}</div>
              <h3 className="mb-4 mt-3 text-muted">
                <strong>{title}</strong>
              </h3>
            </div>
            <div>
              <p className="lead">{text}</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 text-center shadow">
          <div className="lc-block p-4">
            <div>
              <div className="display-6 text-primary">{icon2}</div>
              <h3 className="mb-4 mt-3 text-muted">
                <strong>{title2}</strong>
              </h3>
            </div>
            <div>
              <p className="lead">{text2}</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 text-center">
          <div className="lc-block p-4">
            <div>
              <div className="display-6 text-primary">{icon3}</div>
              <h3 className="mb-4 mt-3 text-muted">
                <strong>{title3}</strong>
              </h3>
            </div>
            <div>
              <p className="lead">{text3}</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
