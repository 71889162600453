import React from "react"
import Container from "react-bootstrap/Container"
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri"
export default function testimonialsStyle({ quote, name, topic }) {
  return (
    <Container className="effect-2 card border-light bg-white py-4">
      <RiDoubleQuotesL className="display-3 text-secondary" />
      <div className="row">
        <div className="col-md-6 offset-md-3 mb-1 text-center">
          <blockquote className="display-5 primary-light handwriting">
            {quote}
          </blockquote>
        </div>
      </div>

      <div className="row">
        <div
          className="col-md-6 offset-md-3 text-center"
          style={{ letterSpacing: "1px" }}
        >
          <cite className="display-7 fw-light font-monospace">- {name}</cite>
          <div>
            <small
              className="fw-lighter font-monospace fst-italic"
              style={{ letterSpacing: "1px" }}
            >
              {topic}
            </small>
          </div>
        </div>
      </div>
      <div className="display-3 text-secondary text-end">
        <RiDoubleQuotesR />
      </div>
    </Container>
  )
}
