import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export default function TextImg({ title, text, link, btn, img, imgAlt }) {
  const image = getImage(img)
  return (
    <Container className="overflow-hidden p-0 mt-0">
      <Row>
        <div
          className="col-md-6 my-auto text-center effect-1"
          style={{ backgroundColor: "#fff", padding: "10vw" }}
        >
          <div className="lc-block mb-3">
            <div>
              <h2 className="text-black-50">{title}</h2>
              <p className="lead">{text}</p>
            </div>
          </div>

          <div className="lc-block">
            <Link to={link} className="btn btn-primary text-uppercase">
              {btn}
            </Link>
          </div>
        </div>

        <div className="col-md-6">
          <GatsbyImage
            image={image}
            alt={imgAlt}
            style={{
              minHeight: "100vh",
            }}
          />
        </div>
      </Row>
    </Container>
  )
}
