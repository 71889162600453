import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"

export default function Hero({ title, subtitle, btn, img }) {
  const image = getImage(img)
  return (
    <div className="effect-1" style={{ display: "grid" }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}

      <GatsbyImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          minHeight: "40vH",
        }}
        layout="fullWidth"
        // You can optionally force an aspect ratio for the generated image

        // This is a presentational image, so the alt should be an empty string
        alt=""
        // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
        image={image}
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",

          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
          minHeight: "40vH",
        }}
      >
        {/* Any content here will be centered in the component */}
        <Container>
          <div className="d-flex justify-content-center h-100">
            <div className="text-white">
              <h1 className="mb-3 text-center">
                <span
                  className="px-2 text-uppercase"
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    lineHeight: "1.39em",
                  }}
                >
                  {title}
                </span>
              </h1>
              <h2 className="mb-3 fw-light text-center fs-5">
                <span
                  className="px-2"
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    lineHeight: "1.39em",
                  }}
                >
                  {subtitle}
                </span>
              </h2>
              <div className="flex-item">
                <Link
                  to="/booking"
                  className="text-center btn btn-primary d-block"
                >
                  {btn}
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}
